import './index.css';
import { MainContentLayout } from '../../components/MainContentLayout';
import React, { useState, useEffect } from 'react';
import { Chip, Menu, MenuList, alpha } from '@material-ui/core';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Checkbox, TextField, Box, Button, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, OutlinedInput, FormControl, Select } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { set } from 'date-fns';
import { tr } from 'date-fns/locale';
import { border, style } from '@mui/system';
const URL = process.env.REACT_APP_API_URL


const StudentInfo = () => {
    const [endDate, setEndDate] = useState(Date.now())
    const [students, setStudents] = useState([]);
    const [users, setUsers] = useState(null);
    const [courses, setCourses] = useState({});
    const [offerings, setOfferings] = useState(null);
    const [expandedRow, setExpandedRow] = useState(null); // State to manage expanded row
    const [selectedTutors, setSelectedTutors] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const url = "https://classsync.thecodeinitiative.ca/registertutor/"

    const fetchData = async () => {
        try {
            let urlStudents = URL + "/students/info"
            const studentsListResponse = await fetch(urlStudents);
            const studentListData = await studentsListResponse.json();
            console.log(studentListData)

            // sort by student_id
            studentListData.sort((a, b) => a.student_id - b.student_id);

            setStudents(studentListData);





        } catch (error) {
            console.error('Failed to fetch data', error);
        }
    };




    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState(students);

    useEffect(() => {

        if (students) {
            if (searchTerm !== '') {
                const results = students.filter(student =>
                    student.student_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    student.guardian_name.toLowerCase().includes(searchTerm.toLowerCase())
                );
                setSearchResults(results);
                console.log("Tutors are ", results)
            } else {
                setSearchResults(students);
            }
        }
    }, [searchTerm, students]);

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };
    // const findUniqueCourses = (tutor_id) => {
    //     let tutorOfferings = offerings?.filter(offering => offering.tutor_id === tutor_id);
    //     let courseList = tutorOfferings?.map(offering => courses?.find(course => course.course_id === offering.course_id)?.course_name);

    //     let uniqueCourseList = [...new Set(courseList)];
    //     console.log(uniqueCourseList)

    //     return uniqueCourseList;
    // }
    const copyToken = async () => {
        try {
            const response = await fetch(URL + '/token');
            const data = await response.json();

            // Now you have the token in `data`, you can copy it to clipboard
            navigator.clipboard.writeText(url + data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const toggleExpandRow = (rowId) => {
        if (expandedRow === rowId) {
            setExpandedRow(null); // Collapse if already expanded
        } else {
            setExpandedRow(rowId); // Expand the clicked row
        }
        setOpenBox(false);
    };



    // Consts for AddCourse DialogBox
    const [openBox, setOpenBox] = useState(false);
    const [courseButtonName, setCourseButtonName] = useState('Add Course');
    const [currTutor, setCurrStudent] = useState(null);
    const [nonSelectedCourses, setNonSelectedCourses] = useState({});

    const [state, setState] = useState(null);


    const upateChanges = () => {
        console.log("Changes made");
        setState(state);
    }


    function calculateAge(dob) {
        const diffMs = Date.now() - dob.getTime();
        const ageDt = new Date(diffMs);

        return Math.abs(ageDt.getUTCFullYear() - 1970);
    }
    return (
        <MainContentLayout
            smallText="Admin Dashboard"
        >



            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className="date-picker-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '20px', maxHeight: '75px', marginTop: '10px', marginBottom: '10px' }}>

                    <div style={{ marginBottom: '0px', display: 'flex', alignItems: 'center' }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            // backgroundColor: '#E1F5FE', // lighter blue
                            color: '#103da2', // dark blue
                            // borderRadius: '20px', 
                            padding: '5px 10px 0px 10px',
                            fontSize: '14px',
                            fontWeight: 'bold'
                        }}>

                        </div>
                        Search by Student or Guardian:                         <TextField
                            type="text"
                            placeholder="Search..."
                            variant="outlined"
                            size="small"
                            value={searchTerm}
                            onChange={handleChange}
                            style={{ marginRight: '10px', marginLeft: '10px' }}
                        />
                    </div>


                </div>
            </LocalizationProvider>

            {/* <div style={{ marginTop: '20px' }}> */}

            <table key={students} style={{ width: '90%', borderCollapse: 'collapse', marginLeft: '30px', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)', borderRadius: '10px', overflow: 'hidden' }}>
                <thead>
                    <tr style={{ borderBottom: '1px solid rgb(221, 221, 221)', backgroundColor: '#103da2', color: '#fff' }}>
                        <th style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold' }}></th> {/* Checkbox */}
                        <th style={{ padding: '10px', textAlign: 'center', }}>ID</th>
                        <th style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', width: '225px' }}>Student</th>
                        <th style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', width: '225px' }}>Guardian</th> {/* Start Date */}
                        <th style={{ padding: '10px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', width: '125px' }}>Grade</th> {/* End Date */}
                    </tr>
                </thead>
                <tbody>
                    {students && searchResults && searchResults.map((student, index) => (
                        <React.Fragment key={index}>
                            <tr >
                                <td className="tutor__table-row-element">
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }} >
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => toggleExpandRow(index)}
                                        >
                                            {expandedRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>

                                    </div>
                                </td>
                                <td className="tutor__table-row-element">{student.student_id}</td>
                                <td className="tutor__table-row-element">{student.student_name}</td>
                                <td className="tutor__table-row-element">
                                    {student.guardian_name}
                                </td>

                                <td className="tutor__table-row-element">
                                    {student.grade}</td>

                            </tr>
                            {expandedRow === index && (
                                <tr>
                                    <td colSpan="7">

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '15px', paddingLeft: '10px', paddingRight: '10px', flexWrap: 'wrap' }} className="registration__row-expand-content">
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '20px', flexWrap: 'wrap' }}>
                                                <div style={{ flex: '1 1 30%', minWidth: '200px', marginBottom: '10px' }}>
                                                    <strong>Date of Birth</strong> <br />
                                                    {new Date(student.dob).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}
                                                </div>
                                                <div style={{ flex: '1 1 30%', minWidth: '200px', marginBottom: '10px' }}>
                                                    <strong>Age</strong> <br />
                                                    {calculateAge(new Date(student.dob))} years
                                                </div>
                                                <div style={{ flex: '1 1 30%', minWidth: '200px', marginBottom: '10px' }}>
                                                    <strong>Pronouns</strong> <br />
                                                    {student.pronouns}
                                                </div>
                                                <div style={{ flex: '1 1 30%', minWidth: '200px', marginBottom: '10px' }}>
                                                    <strong>City</strong> <br />
                                                    {student.city}
                                                </div>
                                                <div style={{ flex: '1 1 30%', minWidth: '200px', marginBottom: '10px' }}>
                                                    <strong>Province</strong> <br />
                                                    {student.province}
                                                </div>
                                                <div style={{ flex: '1 1 30%', minWidth: '200px', marginBottom: '10px' }}>
                                                    <strong>Accommodations</strong> <br />
                                                    {student.accomodations}
                                                </div>


                                            </div>
                                        </div>



                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            {/* </div> */}
        </MainContentLayout>
    );
}
export default StudentInfo;
