import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ForgotPasswordForm from "../../components/ForgotPasswordForm";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
const ForgotPassword = () => {
  // TODO: Redirect to sign up page

  const { role } = useParams()

  const navigate = useNavigate()
  const logInOnClick = () => {

    if (role === "tutor") {
      navigate("/tutor/login");
    } else if (role === "admin") {
      navigate("/admin/login");
    } else {
      navigate("/");
    }
  };

  return (
    <div className="screen-container">
      <div className="content-container">
        <ForgotPasswordForm role={role} />
        <div className="signup-container">
          <button onClick={logInOnClick}>Back to Log In</button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
