import "./index.css";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ArrowLeft } from 'react-bootstrap-icons'
import { useAuth } from "../../contexts/AuthContext";

const EditStudentForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();



  const [formState, setFormState] = useState({
    fname: '',
    lname: '',
    dob: '',
    grade: '',
    city: '',
    province: '',
    pronouns: '',
    accommodations: '',
    color: '',
  })

  // Fetch student data
  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/students/${id}`);
        if (!res.ok) {
          throw new Error('Failed to fetch student data');
        }
        const data = await res.json();
        console.log('Student data OG:', data);
        const formattedDate = data.dob
          ? new Date(data.dob).toISOString().split("T")[0]
          : "";
        // Map API response to form state
        setFormState({
          fname: data.f_name || '',
          lname: data.l_name || '',
          dob: formattedDate || '',
          grade: data.grade || '',
          city: data.city || '',
          province: data.province || '',
          pronouns: data.pronouns || '',
          accommodations: data.accommodations || '',
          color: data.color || '',
        });
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };

    fetchStudentData();
  }, [id]);


  useEffect(() => {
    console.log('Student data CHG:', formState);
  }, [formState]);

  const handleFormChange = (e) => {
    console.log('Input change detected', e.target.name, e.target.value);
    const { name, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleColorSelection = (color) => {
    setFormState((prevState) => ({
      ...prevState,
      color: color,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = ['fname', 'lname', 'dob', 'grade', 'city', 'province', 'color'];
    const emptyFields = requiredFields.filter(field => formState[field] === "");

    if (emptyFields.length > 0) {
      alert(`Please fill out the following fields: ${emptyFields.join(", ")}`);
      return;
    }


    const studentData = {
      f_name: formState.fname,
      l_name: formState.lname,
      dob: formState.dob,
      accommodations: formState.accommodations,
      color: formState.color,
      pronouns: formState.pronouns,
      city: formState.city,
      province: formState.province,
      grade: formState.grade,
    };
    console.log('Student data END:', studentData);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/students/edit/${id}`,
        {
          body: JSON.stringify(studentData),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );
      console.log('Response status:', res.status);
      console.log('Response ok:', res.ok);


      if (res.ok) {
        goBack();
      } else {
        const errorData = await res.json();
        console.log("errorData is ", errorData);
      }
    } catch (error) {
      console.log("error is ", error);
    }
  }
  const colors = ["orange", "green", "purple", "pink", "yellow"];
  const location = useLocation();

  const goBack = () => {
    const from = location.state?.from?.pathname;
    if (from) {
      navigate(from);
    } else {
      navigate('/parentDash');
    }
  };

  return (
    <form className="student-info-form" onSubmit={handleSubmit}>
      <div class="header-row">
        <ArrowLeft size="30px" onClick={goBack} cursor="pointer" />
      </div>
      <div class="header-row">
        <h2 className="add-student-header">Add a Student</h2>
      </div>
      <div className="input-col">
        <h3>Personal</h3>
        <div className="input-row">
          <label>
            First Name
            <input name="fname" type="text" value={formState.fname} onChange={handleFormChange} required />
          </label>
          <label>
            Last Name
            <input name="lname" type="text" value={formState.lname} onChange={handleFormChange} required />
          </label>
        </div>
      </div>
      <div className="input-col">

      </div>
      <div className="input-row">
        <label>
          Date of Birth
          <input name="dob" type="date" value={formState.dob} onChange={handleFormChange} required />
        </label>
        <label>
          Grade
          <select name="grade" type="text" value={formState.grade} onChange={handleFormChange} required>
            <option value="pre">Pre-school</option>
            <option value="kinder">Kindergarten</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>

        </label>
      </div>
      <div className="input-col">
        <h3>Address</h3>
        <div className="input-row">
          <label>
            City
            <input name="city" type="text" value={formState.city} onChange={handleFormChange} required />
          </label>
          <label>
            Province
            <input name="province" type="text" value={formState.province} onChange={handleFormChange} required />
          </label>
        </div>
      </div>
      <div className="input-col">
        <h3>Additional</h3>
        <div className="input-row">
          <label>
            Pronouns
            <input name="pronouns" type="text" value={formState.pronouns} onChange={handleFormChange} />
          </label>
          <label>
            Accommodations
            <input name="accommodations" type="text" value={formState.accommodations} onChange={handleFormChange} />
          </label>
        </div>
      </div>
      <div className="input-col">
        <h3>Assign Color</h3>
        <div className="color-options">
          {/* Generate color options dynamically */}
          {colors.map((color) => (
            <div
              key={color}
              className={`color-option ${color} ${formState.color === color ? "selected" : ""
                }`}
              onClick={() => handleColorSelection(color)}
            ></div>
          ))}
        </div>
      </div>

      <div className="input-row">
        <input type="submit" value="Confirm Edit"
          style={{
            backgroundColor: "#b3defc", // Change this to match your theme color
            transition: "all 0.3s ease",
          }}
          onMouseDown={(e) => e.target.style.backgroundColor = "#d9defc"} // Change color on click
          onMouseUp={(e) => e.target.style.backgroundColor = "#b3defc"} // Revert to original color on mouse up
          onMouseOver={(e) => e.target.style.backgroundColor = "#d9defc"} // Lighten the color on hover
          onMouseOut={(e) => e.target.style.backgroundColor = "#b3defc"} // Original color on mouse out
        />
      </div>
    </form>
  );
};
export default EditStudentForm;
