import Header from "../../components/Header";
import Banner from "../../components/Banner";
import SignUpForm from "../../components/SignUpForm";
import "./index.css";
import RegisterUserForm from "../../components/RegisterUserForm";
import { ParentDashboardLayout } from "../../components/ParentDashboardLayout";

const Signup = () => {


  return (

    <div className="screen-container" style={{ height: 'auto' }}>
      <div className='register-tutor-container' style={{ height: 'auto' }}>
        <RegisterUserForm role='guardian' redirect='/' />
      </div >

    </div >
  );
};

export default Signup;
