import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./index.css"; // Create and import a CSS file for styling

const ForgotPasswordForm = ({ role }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const url = process.env.REACT_APP_API_URL;

    const onSubmit = async (data) => {
        setIsLoading(true);
        setMessage(null);
        setError(null);

        try {
            const response = await fetch(`${url}/parent/forgot-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: data.email, role: role }),
            });

            if (!response.ok) {
                throw new Error("Email not found");
            }

            console.log(response)
            setMessage("A reset password email has been sent to your email address.");
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="forgot-password-form-container">
            <h4 className="forgot-password-form-title">Forgot Password</h4>
            <form className="forgot-password-input-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="forgot-password-input-container">
                    <h5 className="forgot-password-input-title">Email</h5>
                    <input
                        type="text"
                        className="forgot-password-input"
                        {...register("email", {
                            required: "Email is required!",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                            },
                        })}
                    />
                    <p className="forgot-password-error-message">
                        {errors?.email?.message && errors.email.message}
                    </p>
                </div>
                {isLoading ? (
                    <p>Sending...</p>
                ) : (
                    <button type="submit" className="forgot-password-submit-button">
                        Send Reset Email
                    </button>
                )}
                {message && <p className="forgot-password-success-message">{message}</p>}
                {error && <p className="forgot-password-error-message">{error}</p>}
            </form>
        </div>
    );
};

export default ForgotPasswordForm;
