import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import ResetPasswordForm from "../../components/ResetPasswordForm";

const ResetPassword = () => {

  const { role } = useParams();
  const navigate = useNavigate();
  // TODO: Redirect to sign up page
  const logInOnClick = () => {

    if (role === "tutor") {
      navigate("/tutor/login");
    } else if (role === "admin") {
      navigate("/admin/login");
    } else {
      navigate("/");
    }
  };



  return (
    <div className="screen-container">
      <div className="content-container">
        <ResetPasswordForm />
        <div className="signup-container">
          <button onClick={logInOnClick}>Back to Log In</button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
