import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css"; // Create and import a CSS file for styling

const ResetPasswordForm = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { token, role } = useParams();
    const url = process.env.REACT_APP_API_URL;

    const onSubmit = async (data) => {
        setIsLoading(true);
        setMessage(null);
        setError(null);

        try {
            const response = await fetch(`${url}/parent/reset-password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ token, password: data.password, role: role }),
            });

            if (!response.ok) {
                throw new Error("Reset password link expired");
            }

            setMessage("Your password has been reset successfully.");
            navigate("/");
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="reset-password-form-container">
            <h4 className="reset-password-form-title">Reset Password</h4>
            <form className="reset-password-input-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="reset-password-input-container">
                    <h5 className="reset-password-input-title">New Password</h5>
                    <input
                        type="password"
                        className="reset-password-input"
                        {...register("password", {
                            required: "Password is required!",
                            minLength: {
                                value: 6,
                                message: "Password must be at least 6 characters long",
                            },
                        })}
                    />
                    <p className="reset-password-error-message">
                        {errors?.password?.message && errors.password.message}
                    </p>
                </div>
                <div className="reset-password-input-container">
                    <h5 className="reset-password-input-title">Confirm Password</h5>
                    <input
                        type="password"
                        className="reset-password-input"
                        {...register("confirmPassword", {
                            required: "Please confirm your password!",
                            validate: (value) =>
                                value === watch("password") || "Passwords do not match",
                        })}
                    />
                    <p className="reset-password-error-message">
                        {errors?.confirmPassword?.message && errors.confirmPassword.message}
                    </p>
                </div>
                {isLoading ? (
                    <p>Resetting...</p>
                ) : (
                    <button type="submit" className="reset-password-submit-button">
                        Reset Password
                    </button>
                )}
                {message && <p className="reset-password-success-message">{message}</p>}
                {error && <p className="reset-password-error-message">{error}</p>}

            </form>
        </div>
    );
};

export default ResetPasswordForm;