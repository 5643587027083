import { Avatar } from "primereact/avatar";
import "./index.css";
import { useEffect, useState } from "react";
import { sampleData } from "./fakeData";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { Redirect, useNavigate } from "react-router-dom";
import { clsx } from "clsx";
const URL = process.env.REACT_APP_API_URL

export const TutorInfoCard = ({ tutorId, tutor, courses }) => {
  const tutorName = tutor.firstname + " " + tutor.lastname;
  const university = tutor.university;
  const description = tutor.description;
  const about = tutor.bio;
  const [offerings, setOfferings] = useState([]);
  const [showMore, setShowMore] = useState(false);
  //   console.log(courses);
  const url = URL;
  const navigate = useNavigate()

  //   console.log(tutorId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = tutorId;
        // Fetch courses data
        // const courses = await fetch(`${url}/tutorProfile/courses?userId=${id}`);
        // const coursesData = await courses.json();

        // setCourses(coursesData);

        // Fetch courses data
        const coursesResponse = await fetch(`${url}/tutor/offerings`);
        const coursesData = await coursesResponse.json();

        // Transform coursesData into options format
        const options = coursesData.map((course) => ({
          value: course.course_id,
          label: `${course.course_difficulty} ${course.course_name}`,
          color: course.color,
        }));

        // Fetch profile data


        // Fetch Offerings data

        const offeringsResponse = await fetch(`${url}/tutor/offering?id=${id}`);
        const offeringsData = await offeringsResponse.json();
        setOfferings(offeringsData);

        // Filter selectedOptions based on offeringsData
        const filteredOptions = options.filter((option) =>
          offeringsData.includes(option.value)
        );
        setOfferings(filteredOptions);


        // console.log("Tutor Name", tutorName.trim().length);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once after the initial render
  //   console.log(courses);
  //   console.log(offerings);
  return (
    <>
      <div className="pc tutor-info-card__container">
        <div className="tutor-info-card__overview">
          <div className="tutor-info-card__avatar-container">
            <Avatar
              className="tutor-info-card__avatar"
              image={tutor.image}
              size="large"
              shape="circle"
            // shape="square"
            />
          </div>
          <div className="tutor-info-card__details">
            {
              <div className="tutor-info-card__name">
                {tutorName}
              </div>
            }
            <div className="tutor-info-card__description">
              {description}
            </div>
            <div className="tutor__courses__container">
              <img className="tutor-info-card__icon" src="/book.svg" alt="g" />
              <span className="tutor-info-card__text">Teaches {courses}</span>
            </div>
            <div className="tutor__languages__container">
              <img
                className="tutor-info-card__icon"
                src="/language.svg"
                alt="g"
              />
              <span className="tutor-info-card__text">
                Speaks {tutor.languages}
              </span>
            </div>
            <div className="tutor__languages__container">
              <img
                className="tutor-info-card__icon"
                src="/graduation1.svg"
                alt="g"
              />
              <span className="tutor-info-card__text">
                Studies at {university ? university : sampleData.education}
              </span>
            </div>
            <div className="tutor-info-card__about-me">
              <div
                className={clsx(
                  showMore && "show-more__container--visible",
                  !showMore && "show-more__container--hidden"
                )}
              >
                <p className="m-0 ">{about ? about : sampleData.aboutMe} </p>
              </div>
              <button
                className="show-more__button"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show Less" : "Show More"}
              </button>
            </div>
          </div>
          <div className="tutor-info-card__more-info__container">
            <div>
              <div className="tutor-info-card__rating">
                {/* <span className="tutor-info__rating-number"></span>{" "} */}
              </div>
              <div className="tutor-info-card__learn-more">
                <a href={`/viewTutor/${tutorId}`}>
                  {" "}
                  <Button
                    className="tutor-info__learn-more"
                    label="Learn More"
                    style={{
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                    }}
                    onMouseDown={(e) => e.currentTarget.style.backgroundColor = "#0056b3"} // Change color on click
                    onMouseUp={(e) => e.currentTarget.style.backgroundColor = "#103da2"} // Revert to original color on mouse up
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#0069d9"} // Darker shade on hover
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = "#103da2"} // Original color on mouse out

                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb" >
        <div className="tutor-info-card__overview">
          <div className="tutor-info-card__avatar-container">
            <Avatar
              className="tutor-info-card__avatar"
              image={tutor.image}
              size="large"
              shape="circle"
            />
          </div>
          <div className="tutor-info-card__details">
            {
              <div className="tutor-info-card__name">
                <a href={`/viewTutor/${tutorId}`}>
                  {tutorName}</a>
              </div>
            }
            <div className="tutor-info-card__description">
              {description}
            </div>
            <div className="tutor__courses__container">
              <img className="tutor-info-card__icon" src="/book.svg" alt="g" />
              <span className="tutor-info-card__text">Teaches {courses}</span>
            </div>
            <div className="tutor__languages__container">
              <img
                className="tutor-info-card__icon"
                src="/language.svg"
                alt="g"
              />
              <span className="tutor-info-card__text">
                Speaks {tutor.languages}
              </span>
            </div>
            <div className="tutor__languages__container">
              <img
                className="tutor-info-card__icon"
                src="/graduation1.svg"
                alt="g"
              />
              <span className="tutor-info-card__text">
                Studies at {university ? university : sampleData.education}
              </span>
            </div>
            <div className="tutor-info-card__about-me">
              <div
                className={clsx(
                  showMore && "show-more__container--visible",
                  !showMore && "show-more__container--hidden"
                )}
              >
                <p className="m-0 ">{about ? about : sampleData.aboutMe} </p>
              </div>
              <button
                className="show-more__button"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? "Show Less" : "Show More"}
              </button>
            </div>
          </div>
          <div className="tutor-info-card__more-info__container">
            <div>
              <div className="tutor-info-card__rating">
                {/* <span className="tutor-info__rating-number"></span>{" "} */}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
