import Header from "../../components/Header";
import Banner from "../../components/Banner";
import "./index.css";
import EditStudentForm from "../../components/EditStudentForm";
import Plus from "../../assets/plus.png";
import Notification from "../../assets/notifications.png";
import Bag from "../../assets/bag.png";

import "./index.css";

const EditStudent = (props) => {
  return (
    <div className="page-container">


      <div className="student-info-container add-student">
        <EditStudentForm />
        <hr className="line"></hr>
      </div>

    </div>
  );
};

export default EditStudent;