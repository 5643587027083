import React, { useEffect } from "react";
import Calendar from "../../components/TutorCalendar";
import "./index.css"; // Import your custom styles
const AppointmentCalendar = () => {




    return (

        <Calendar />


    );
};

export default AppointmentCalendar;
